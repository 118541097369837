import React, { FC, Suspense, useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './SectionPage.module.scss';
import { MoviesList } from '../../components/MoviesList';
import { CustomSpinner } from '../../shared/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import { SuspenseImg } from '../../utils/imageSuspense';
import { useSectionQuery } from './query';
import { AuthContext } from 'src/context/auth';
import { URLSearchQueryStateModel } from '../search/types';
import {generateImageUrl} from "../../utils/cdnService";

type Props = {
  type: string;
};

const SectionPage: FC<Props> = ({ type }) => {
  const navigate = useNavigate();
  let { slug } = useParams<{ slug: string }>();
  const authContext = useContext(AuthContext);
  const { isAuthenticated } = authContext;
  const [query] = useState<URLSearchQueryStateModel>({
    page: 1,
  });

  const fetchUrls = {
    theme: `/homepage/themes/${slug}`,
    promotion: `/promotions/${slug}`,
  };

  const { sectionData, isSectionQueryLoading, isSectionError, isError } = useSectionQuery(
    query,
    isAuthenticated,
    fetchUrls[type]
  );

  const renderMovieList = () => {
    if (isSectionQueryLoading && !sectionData) {
      return <CustomSpinner />;
    }
    return <MoviesList data={sectionData.content} />;
  };

  const renderBanners = () => {
    if (!isSectionQueryLoading && sectionData && sectionData.image)
      return (
        <div className={styles.headerImage}>
          <SuspenseImg alt="" src={generateImageUrl('gcore', 0, 0, sectionData.image)} />
        </div>
      );
  };

  if (!isSectionQueryLoading && (isError || isSectionError)) {
    navigate('/');
  }
  return (
    <Container>
      <Suspense fallback={<CustomSpinner />}>
        <Row>
          <Col sm={12}>{renderBanners()}</Col>
        </Row>
        <h2>{!isSectionQueryLoading && sectionData && sectionData.title}</h2>
        <Row>{!isSectionQueryLoading && sectionData && sectionData.content && renderMovieList()}</Row>
      </Suspense>
    </Container>
  );
};

export default SectionPage;
