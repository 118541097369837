import React, { FC } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './Prices.module.scss';
import { generateImageUrl } from '../../../utils/cdnService';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Prices: FC = () => {
  const navigate = useNavigate();
  const {
    i18n: { language },
  } = useTranslation();

  const handleCardClick = () => {
    navigate(`/${language}/blue-movie-plus`);
  };

  return (
    <div className={styles.container}>
      <Row>
        <Col sm={12} style={{ padding: '0 52px' }}>
          <h2>Preise</h2>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <div className={styles.priceContainer}>
            <Row>
              <Col sm={10}>
                <h2>ABO</h2>
                <Col sm={12}>
                  <ul className={styles.checkList}>
                    <li>Blue Movie Plus: Streamen ohne Limit</li>
                    <li>Über 2.500 ausgewählte Filme</li>
                    <li>Diskrete Bestellung und perfekter Jugendschutz</li>
                  </ul>
                </Col>
              </Col>
            </Row>
            <Row className={styles.ticketWrapper}>
              <Col sm={4} className={styles.ticketColumnWrapper}>
                <div className={styles.ticket} onClick={handleCardClick}>
                  <img
                    className={styles.imgResponsive}
                    src={generateImageUrl('gcore', 344, 165, '/payment_assets/BMO-month-22-ticket-com.png')}
                    alt="Bluemovie Ticket"
                  />
                </div>
                <p>Unser Top-Angebot: Ein ganzes Jahr unbegrenzt Zugriff auf alle Filme</p>
              </Col>
              <Col sm={4} className={styles.ticketColumnWrapper}>
                <div className={styles.ticket} onClick={handleCardClick}>
                  <img
                    className={styles.imgResponsive}
                    src={generateImageUrl('gcore', 344, 165, '/payment_assets/BMO-year-1650-ticket-com.png')}
                    alt="Bluemovie Ticket"
                  />
                </div>
                <p>Unser Bestseller: Das volle Programm, monatlich kündbar</p>
              </Col>
              <Col sm={4} className={styles.ticketColumnWrapper}>
                <div className={styles.ticket} onClick={handleCardClick}>
                  <img
                    className={styles.imgResponsive}
                    src={generateImageUrl('gcore', 344, 165, '/payment_assets/BMO-90days-45-ticket-com.png')}
                    alt="Bluemovie Ticket"
                  />
                </div>
                <p>Nur jetzt: Drei Monate Blue Movie Plus für einmalig 45€</p>
              </Col>
            </Row>
          </div>

          <div className={styles.priceContainer}>
            <Row>
              <Col sm={10}>
                <h2>LEIHE</h2>
                <Col sm={12}>
                  <ul className={styles.checkList}>
                    <li>9€ pro Film</li>
                    <li>Sofort abspielbar</li>
                    <li>48 Stunden verfügbar</li>
                  </ul>
                </Col>
              </Col>
            </Row>
          </div>

          <div className={styles.priceContainer}>
            <Row>
              <Col sm={10}>
                <h2>KAUF</h2>
                <Col sm={12}>
                  <ul className={styles.checkList}>
                    <li>Erotikfilme für jeden Geschmack ab 12€</li>
                    <li>Als Download jederzeit offline verfügbar</li>
                    <li>Immer in HD-Qualität</li>
                  </ul>
                </Col>
              </Col>
            </Row>
          </div>

          {/*<div className={styles.priceContainer}>*/}
          {/*  <Row>*/}
          {/*    <Col className={styles.bottom} sm={12}>*/}
          {/*      <p className="small">Alle Preise inkl. MwSt.</p>*/}
          {/*    </Col>*/}
          {/*  </Row>*/}
          {/*</div>*/}
        </Col>
      </Row>
    </div>
  );
};

export default Prices;
