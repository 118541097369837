import React, { FC, useRef } from 'react';
import styles from './MainSlider.module.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MainSliderImageModel } from './types';
import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import {generateImageUrl} from "../../utils/cdnService";


type Props = {
  images: MainSliderImageModel[];
};
const MainSlider: FC<Props> = ({ images }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();

  const ref = useRef<SwiperRef | null>(null);

  const handleNext = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper?.slideNext();
    }
  };

  const handlePrev = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper?.slidePrev();
    }
  };
  const params = {
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    allowTouchMove: true,
    preventClicks: false,
    preventClicksPropagation: false,
    navigation: {
      nextEl: `${styles.nextButton}`,
      prevEl: `${styles.prevButton}`,
    },
    slidesPerView: 1,
  };

  const handleClick = (url: string | null) => {
    if (url) {
      navigate(`${language}${url}`);
    }
  };

  return (
    <div className={styles.mainSlider}>
      <Swiper ref={ref} className={styles.mainSlider} modules={[Navigation, Autoplay]} {...params}>
        {images &&
          images.map((image) => {
            return (
              <SwiperSlide key={image.id}>
                <div onClick={() => handleClick(image.url)}>
                  <img src={generateImageUrl('gcore', 0, 0, image.image)} alt="" />
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
      <div onClick={handleNext} className={styles.nextButton}>
        <i className="fa fa-angle-right" aria-hidden="true" style={{ padding: '0px 4px' }} />{' '}
      </div>
      <div onClick={handlePrev} className={styles.prevButton}>
        <i className="fa fa-angle-left" aria-hidden="true" />
      </div>
    </div>
  );
};
export default MainSlider;
