import React, { FC, useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import image from '../../assets/img/BMO-cancelationyear-ticket.png';
import styles from './SpecialPage.module.scss';
import Form from 'react-bootstrap/Form';
import { AuthContext } from '../../context/auth';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import Spinner from '../../shared/Spinner/Spinner';
import { LoginButton } from '../../components/UI/LoginButton';
import { PaymentModal } from '../../components/UI/Modals/PaymentModal';
import { useDeepLinkValidationMutation } from './mutation';
import { SpecialPageSelectedTickerModel, SpecialPageSelectedTicket } from './types';
import { usePaymentQuery } from '../blueMoviePlus/query';
import { usePaymentInitMutation, usePaymentMutation } from '../blueMoviePlus/mutation';
import { oneClickPayment } from '../blueMoviePlus/utils';
import { generateImageUrl } from '../../utils/cdnService';
import { SessionManagementModal } from '../../components/UI/Modals/SessionManagementModal';
import useHandleSepaPayment from '../../hooks/useHandleSepaPayment';
const baseAppUrl = process.env.REACT_APP_URL;

const SpecialPage: FC = () => {
  const authContext = useContext(AuthContext);
  let { hash } = useParams<{ hash: string }>();
  const [selectedTicket, setSelectedTicket] = useState<SpecialPageSelectedTicket>({ id: 23 });
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const location = useLocation();
  const { userLocation, login, loading, error, showModal, closeSessionModal, lastLoginData } = authContext;
  const navigate = useNavigate();
  const [spinner, setIsLoading] = useState(false);
  const [hashValid, setHashValid] = useState(false);
  const [form, setForm] = useState({
    username: '',
    pin: '',
  });
  const [formErrors, setFormErrors] = useState({
    username: '',
    pin: '',
  });
  const [pin, setPin] = useState('');
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const { isAuthenticated, loadUser } = authContext;

  const [selectedType, setSelectedType] = useState<SpecialPageSelectedTickerModel>({
    id: -1,
    gateway: '',
    last_id: -1,
  });
  const { payment, isPaymentLoading } = usePaymentQuery(isAuthenticated);
  const { mutateDeepLinkValidation, isDeepLinkValidationMutationLoading } = useDeepLinkValidationMutation();
  const { mutate: paymentInitMutate } = usePaymentInitMutation();
  const { mutate } = usePaymentMutation();

  const handleSepaPayment = useHandleSepaPayment(selectedType);

  const checkHash = async () => {
    mutateDeepLinkValidation(
      {
        code: hash as string,
      },
      {
        onSuccess(data: any) {
          setHashValid(true);
          setSelectedTicket({
            id: data.data.product_id,
            price_id: data.data.price_id,
            price: data.data.price,
          });
        },
        onError() {
          console.error('Something went wrong not 200 status');
          navigate('/');
        },
      }
    );
  };

  const validateForm = () => {
    let valid = true;
    const errors = {
      username: '',
      pin: '',
    };

    if (form.username === '') {
      errors.username = 'Username is required';
      valid = false;
    }

    if (pin === '') {
      errors.pin = 'PIN is required';
      valid = false;
    } else if (pin.length !== 4) {
      errors.pin = 'PIN must be 4 digits';
      valid = false;
    }

    setFormErrors(errors);
    return valid;
  };

  useEffect(() => {
    if (isAuthenticated) {
      checkHash();
    }
    if (payment?.last_payments?.length > 0) {
      setSelectedType({
        id: 0,
        gateway: payment.last_payments[0].gateway,
        last_id: payment.last_payments[0].id,
      });
    }
    // We need checkHash() based on isAuthenticated, payment.last_payments
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, payment?.last_payments]);

  const handleSubmit = async () => {
    oneClickPayment(
      setIsLoading,
      setShowPaymentModal,
      selectedType,
      mutate,
      loadUser,
      language,
      navigate,
      location,
      paymentInitMutate,
      selectedTicket.price_id,
      selectedTicket.id
    );
  };

  const handleConfirm = () => {
    if (lastLoginData.username !== '' && lastLoginData.pin !== '') {
      const loginDataWithSessionDeletion = { ...lastLoginData, delete_sessions: true };
      login(loginDataWithSessionDeletion);
      closeSessionModal();
    }
  };

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    if (!form.username || !pin) return;
    e.preventDefault();

    if (validateForm()) {
      let data = {
        username: form.username,
        pin,
      };

      login(data);
    }

    let data = {
      username: form.username,
      pin,
    };
    login(data);
  };
  const handleTest = (input: string | null) => {
    const reg = new RegExp('^\\d+$');
    if (input && reg.test(input) && pin?.length < 4) {
      setPin(pin + input);
    } else if (input === null) {
      setPin(pin.slice(0, -1));
    }
  };
  const handlePinChange = (e: React.SyntheticEvent) => {
    const { nativeEvent } = e;
    handleTest((nativeEvent as InputEvent).data);
  };

  const renderLogin = () => {
    if (isAuthenticated) {
      return;
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    };
    return (
      <div style={{ margin: '20px 0' }} className={styles.loginWrapper}>
        <h3>{t('singleMoviePage.loginDialog')}</h3>
        <form onSubmit={handleLogin} autoComplete="off">
          <Row className={styles.formWrapper}>
            <Col xs={12} sm={6} lg={4} style={{ marginBottom: '5px' }}>
              <input
                autoComplete="off"
                name="username"
                placeholder={t('singleMoviePage.customerNumber')}
                type="text"
                value={form.username}
                onChange={handleChange}
              />
              {formErrors.username && <div>{formErrors.username}</div>}
            </Col>
            <Col xs={12} sm={6} lg={4} style={{ marginBottom: '5px' }}>
              <input
                maxLength={4}
                name="pin"
                placeholder={`${userLocation !== 'AT' ? '18+' : 'Sky'} ${t('singleMoviePage.pinCode')}`}
                autoComplete="off"
                type="text"
                value={pin
                  .split('')
                  .map((item) => '⚫')
                  .join('')}
                onChange={handlePinChange}
              />
              {formErrors.pin && <div>{formErrors.pin}</div>}
            </Col>
            {error && <div className={styles.errors}>{error}</div>}
            <Col xs={12} sm={6} lg={4}>
              <LoginButton loading={loading} handleLogin={handleLogin} disabled={pin?.length < 4 ? true : false} />
            </Col>
          </Row>
        </form>
      </div>
    );
  };
  const renderPaymentMethods = () => {
    if (selectedTicket.id === 3) {
      return (
        payment &&
        payment.payment_types
          .filter((item) => item.name !== 'Epoch')
          .map((item) => (
            <Col sm={6}>
              <div
                onClick={() => setSelectedType(item)}
                className={
                  item.id === selectedType.id ? `${styles.typeWrapper} ${styles.selected}` : `${styles.typeWrapper}`
                }
              >
                <Form.Check
                  className={styles.radioButton}
                  type="radio"
                  onChange={() => setSelectedType(item)}
                  checked={item.id === selectedType.id}
                />
                <img alt="cdn-img" src={generateImageUrl('gcore', 0, 0, item.image)} />
              </div>
            </Col>
          ))
      );
    } else if (selectedTicket.id === 5) {
      return (
        payment &&
        payment.payment_types
          .filter((item) => item.name !== 'Epoch' && item.name !== 'MicroPayment Sofort')
          .map((item) => (
            <Col sm={6}>
              <div
                onClick={() => setSelectedType(item)}
                className={
                  item.id === selectedType.id ? `${styles.typeWrapper} ${styles.selected}` : `${styles.typeWrapper}`
                }
              >
                <Form.Check
                  className={styles.radioButton}
                  type="radio"
                  onChange={() => setSelectedType(item)}
                  checked={item.id === selectedType.id}
                />
                <img alt="cdn-img" src={generateImageUrl('gcore', 0, 0, item.image)} />
              </div>
            </Col>
          ))
      );
    } else {
      return (
        payment &&
        payment.payment_types
          .filter((item) => item.name !== 'MicroPayment Sofort' && item.name !== 'Epoch')
          .map((item) => (
            <Col sm={6}>
              <div
                onClick={() => setSelectedType(item)}
                className={
                  item.id === selectedType.id ? `${styles.typeWrapper} ${styles.selected}` : `${styles.typeWrapper}`
                }
              >
                <Form.Check
                  className={styles.radioButton}
                  type="radio"
                  onChange={() => setSelectedType(item)}
                  checked={item.id === selectedType.id}
                />
                <img alt="cdn-img" src={generateImageUrl('gcore', 0, 0, item.image)} />
              </div>
            </Col>
          ))
      );
    }
  };

  return (
    <Container>
      <PaymentModal
        show={showPaymentModal}
        handleClose={() => setShowPaymentModal(false)}
        amount={selectedTicket.price as number}
        handlePayment={handleSepaPayment}
      />
      <Row>
        <Col sm={12}>
          <img src={image} alt="main" />
        </Col>
      </Row>
      {renderLogin()}
      {!isPaymentLoading && !isDeepLinkValidationMutationLoading ? (
        hashValid && (
          <Row style={{ width: '100%', marginTop: 25 }}>
            {payment && payment.last_payments?.length > 0 && (
              <Col sm={6}>
                <div
                  onClick={() => setSelectedType({ ...selectedType, id: 0 })}
                  className={
                    selectedType.id === 0 ? `${styles.typeWrapper} ${styles.selected}` : `${styles.typeWrapper}`
                  }
                >
                  <Form.Check
                    className={styles.radioButton}
                    type="radio"
                    onChange={() =>
                      setSelectedType({
                        ...selectedType,
                        id: 0,
                      })
                    }
                    checked={selectedType.id === 0}
                  />
                  <Form.Select
                    onChange={(e) => {
                      const gateway = payment.last_payments.filter((item) => item.id === Number(e.target.value))[0]
                        .gateway;
                      setSelectedType({
                        last_id: e.target.value,
                        gateway: gateway,
                        id: 0,
                      });
                    }}
                  >
                    {payment && payment.last_payments.map((p) => <option value={p.id}>{p.info}</option>)}
                  </Form.Select>
                </div>
              </Col>
            )}

            {renderPaymentMethods()}
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                margin: '50px 0',
              }}
            >
              <button
                style={{ padding: '8px 50px' }}
                onClick={handleSubmit}
                className={styles.button}
                disabled={spinner}
              >
                {selectedType.id === 0 ? t('bmPlusPage.orderForFee') : t('bmPlusPage.continue')}
              </button>
            </div>
            <hr
              style={{
                borderColor: 'white',
                marginBottom: '60px',
                width: '100%',
              }}
            />
          </Row>
        )
      ) : (
        <Spinner />
      )}
      {showModal && (
        <SessionManagementModal centered={true} show={showModal} onHide={closeSessionModal} onConfirm={handleConfirm} />
      )}
    </Container>
  );
};

export default SpecialPage;
